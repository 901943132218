<template>
    <KCourse loader-class="MBcont" course-id="1" title="Анатомия головы" :items="items">
        
            <div class="MBtextcont">
                <p>
                    Вот ОНО! Последнее занятие марафона. Так сказать, венец нашего
                    плодотворного пути)
                </p>
            </div>

            <VideoView video-id="9ad44e9dd41b49e8a1e0ba2698b6bab5"/>

            <div class="MBtextcont">
                <p>
                    Надеюсь, что ваша работа продвигается уже намного легче и быстрее, и
                    совсем скоро вы скажете себе и нам:
                </p>
                <p>
                    "Я это сделал(а)! Я сдержал(а) данное слово! Вот оно лицо моей новой
                    куклы! Троекратное УРА, УРА, УРА!"
                </p>
                <p>Здорово, правда? )</p>
            </div>
        
    </KCourse>
</template>

<script>
    import VideoView from "../../Widgets/VideoView";
    import KCourse from "../../Widgets/KCourse";

    export default {
        name: "MBCourseHead",
        components: {
            VideoView,
            KCourse,
        },
        data() {
        return {
            items: [
                { title: "Лепим череп", url:"/mycourses/head/1"},
                { title: "Делаем обрубовку", url:"/mycourses/head/2"},
                { title: "Лепим лицо", url:"/mycourses/head/3"},
                { title: "Основа из La Doll", url:"/mycourses/head/4"},
                { title: "Создаем объемы", url:"/mycourses/head/5"},
                { title: "Доработка деталей", url:"/mycourses/head/6"},
            ],
        }
        }
    }
</script>